import Loading from "components/shared-components/Loading";
import { DOCTOR_PREFIX_PATH } from "configs/AppConfig";
import React, { lazy, Suspense, useEffect, useRef, useState } from "react";
import { Route, Switch } from "react-router";
import { CURRENT_USER } from "redux/constants/Auth";
import PageNotFoundPage from "views/PageNotFound";
import UnAuthorizedPage from "views/UnAuthorized";
import { VIEW_ALL_DISCREPANCIES, VIEW_TOP_TESTS, VIEW_STAT_ORDERS, VIEW_ALL_INCOMPLETE_RESULTS, VIEW_ALL_RELEASED_RESULTS, VIEW_COMPLETED_RESULTS, VIEW_DR_PROFILE_UPDATE_PAGE, VIEW_DRS_CHAT_ROOM, VIEW_NEWORDERS, VIEW_ALL_ORDERS, VIEW_PANIC_AND_ABNORMAL_TESTS, VIEW_SEASONAL_REPORT, VIEW_DR_CUSTOMER_SUPPORT_PAGE, VIEW_TESTDIRECTORIES, VIEW_SHARED_RESULTS_BY_OTHER_Doctors, VIEW_SHARED_RESULTS_BY_OTHER_DOCTORS } from "configs/UserPermissions";
import { DoesThisUserHasAPermissionToThisComponent, doesThisUserIsDoctor, getCurrentUserId } from "utils/CurrentUserUtil";
import { db } from "auth/FirebaseAuth";
import { ShowNotification } from "components/shared-components/NotificationApi/ShowNotification";
import { useHistory } from 'react-router-dom';
import { NewTest } from "./newTest/newTest";
import { notification } from "antd";
import { SmileOutlined } from "@ant-design/icons";
import liveChatSound from './audio/livechat.mp3';
import { env } from "configs/EnvironmentConfig";

// create notification audio object
const audio = new Audio(liveChatSound)

 
const DoctorAppViews = () => {
  const currentUser = JSON.parse(localStorage.getItem(CURRENT_USER));
  const role = currentUser?.role;
  const history = useHistory();
  const [newTestAdded, setNewTestAdded] = useState(false);
  const [clicked, setClicked] = useState(true);
  const prevSharedResultsLength = useRef(0);
  const prevSharedRepliesLength = useRef(0);

  // Notify when result is shared
  useEffect(() => {    
    const unsubscribe = db.collection('SharedResults').onSnapshot(snapshot => {
        const results = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
        // Flatten the messages arrays from each result
        const allMessages = results.flatMap(result => result.messages || []);

        // Sort the messages by timestamp and get the latest one
        const latestRequest = allMessages.sort((a, b) => b.timestamp.seconds - a.timestamp.seconds)[0];
        const senderName = latestRequest?.senderName
        const receiverID = latestRequest?.receiverID
        const requestKey = latestRequest?.sampleId
        const requestSentKey = window.localStorage.getItem('requestKey');

        // Notify user if new results have been shared
        if (results.length > prevSharedResultsLength.current && receiverID === getCurrentUserId() && requestSentKey !== requestKey) {
            audio.play().catch(error => console.log(error))
            notification.open({
                message: 'New Result Shared',
                description: `${senderName} shared a new result with you`,
                placement: 'topRight',
                type: 'info',
                onClick: () => {
                  history.push(`${DOCTOR_PREFIX_PATH}/results/shared/me`);
                },
                style: {cursor: 'pointer'},
            });
            window.localStorage.setItem('requestKey', requestKey);
        }
        // Notify when result reply is sent
        const allReplies = allMessages.flatMap(message => message.replies || [])
        const repliesWithUniqueReceiver = allReplies.filter(reply => reply.receiverID !== reply.senderID)
        const latestReply = repliesWithUniqueReceiver.sort((a, b) => b.timestamp.seconds - a.timestamp.seconds)[0];
        const latestReplyShownKey = window.localStorage.getItem('latestReplyKey') === latestReply?.id ? true : false;

        // Update previous shared results length
        prevSharedResultsLength.current = results.length;

        if(allReplies.length > prevSharedRepliesLength.current && latestReply.receiverID === getCurrentUserId() && latestReplyShownKey === false){
          audio.play().catch(error => console.log(error))
          notification.open({
            message: 'New Result Reply Shared',
            description: `${latestReply.senderName} replied to your shared result "${latestReply.comment}"`,
            placement: 'topRight',
            type: 'info',
            onClick: () => {
              history.push(`${DOCTOR_PREFIX_PATH}/results/shared/me`);
            },
            style: {cursor: 'pointer'},
          });
          window.localStorage.setItem('latestReplyKey', latestReply.id);
        }
        // Update the replies length
        prevSharedRepliesLength.current = allReplies.length;

        

    }, error => {
        console.error("Error fetching shared results:", error);
    });

    // Cleanup Firestore listener on component unmount
    return () => {
        console.log('Cleaning up Firestore listener');
        unsubscribe();
    };
}, []);

  useEffect(() => {
    // Function to fetch test directories data
    const fetchTestDirectoriesData = async () => {
      const baseUrl = env.API_BASE_URL;
      let previousTestsLength = 292;
  
      const response = await fetch(`${baseUrl}/test-directories`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("auth_token")}`
        }
      });
  
      const fetchedJSONData = await response.json();
      const currentTestsLength = fetchedJSONData.data?.length;
  
      // Sort the data array in descending order of the timestamp
      fetchedJSONData.data?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  
      if (currentTestsLength > previousTestsLength) {
        const newTest = fetchedJSONData.data[0];
        //console.log('Newly created',newTest)
  
        // Query Firestore to check if the test already exists
        db.collection('newly created tests').where('name', '==', newTest.test_fullname).get()
          .then((querySnapshot) => {
            if (querySnapshot.empty) {
              // The test does not exist in Firestore, so add it
              db.collection('newly created tests').add({
                testId: newTest.id,
                name: newTest.test_fullname,
                currently_available: newTest.currently_available,
                department_name: newTest.department_name,
                estimated_time_for_result: newTest.estimated_time_for_result,
                created_at: new Date()
              })
                .then((docRef) => {
                  console.log("Document written with ID: ", docRef.id);
                  setNewTestAdded(true);
                  //ShowNotification("info", "topRight", `New test ${newTest.test_fullname} has been added`);
                 // ShowNotification("info", "topRight", <TestName name={newTest.test_fullname} />);
                })
                .catch((error) => {
                  console.error("Error adding document: ", error);
                });
            }
          })
          .catch((error) => {
            console.log("Error getting documents: ", error);
          });
      }
  
      // Update the previousTestsLength
      previousTestsLength = currentTestsLength;
    };
  
    // Get the chat notifications from db
    const unsubscribeNotifications = db.collection('notifications')
      .where('reciever_id', '==', currentUser.id)
      .orderBy('timestamp', 'desc')
      .onSnapshot(snapshot => {
        const notifications = snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() }));
  
        // If there are any notifications, show a notification for the most recent one
        if (notifications.length > 0) {
          const mostRecentNotification = notifications[0];
          audio.play().catch(error => console.log(error))
          ShowNotification("info", "topRight", mostRecentNotification.data.text, mostRecentNotification.data.userImg, history, false);
  
          // Delete the notification after it's shown
          db.collection('notifications').doc(mostRecentNotification.id).delete();
        }
      });
    
    // call the functions
    fetchTestDirectoriesData();
  
    // Cleanup function to unsubscribe from the listeners
    return () => {
      unsubscribeNotifications();
      //unsubscribeNewTests();
    };
  
  }, [currentUser.id, history, currentUser]);

  useEffect(() => {
    // Listen for changes to the 'newly created tests' collection
    const showNewTestNotifications = () => {      
      db.collection('newly created tests').orderBy('created_at', 'desc').limit(1).get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const notificationKey = `NewTestNotificationShownKey_${currentUser.id}`;
            const hasNotificationBeenShown = Boolean(localStorage.getItem(notificationKey));

            currentUser.showNewTestNotifications = hasNotificationBeenShown;
            if (hasNotificationBeenShown === false && clicked === true) {
              audio.play().catch(error => console.log(error)) // play audio
              notification.open({
                message: `Good news! ${currentUser.name}`,
                description: (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    As per request, we have brought in a new test into our system. Please check it out.
                    <NewTest testDetail={doc.data()} history={history} setClicked={setClicked} />
                  </div>
                ),
                icon: <SmileOutlined style={{ color: '#108ee9' }} />,
              });
              localStorage.setItem(notificationKey, true);
            }
          });
        });
    };
    showNewTestNotifications();
  }, [newTestAdded, currentUser, history, clicked] )
  
  return (
    <div>
      <Suspense fallback={<Loading cover="content" />}>
      { 
         doesThisUserIsDoctor() ? 
         <Switch>
          <Route 
            exact
            path={`${DOCTOR_PREFIX_PATH}`}
            component={lazy(() => import(`./dashboard/list/index`))}
          />
          <Route
            exact
            path={`${DOCTOR_PREFIX_PATH}/dashboard`}
            component={lazy(() => import(`./dashboard/list/index`))}
          />
          {DoesThisUserHasAPermissionToThisComponent(VIEW_ALL_DISCREPANCIES) && (
                <Route
                  exact
                  path={`${DOCTOR_PREFIX_PATH}/results/corrected/all`}
                  component={lazy(() => import(`../admin-views/discrepancy/all/list`))}
                />
          )}

          {DoesThisUserHasAPermissionToThisComponent(VIEW_ALL_RELEASED_RESULTS) && (
                <Route
                  exact
                  path={`${DOCTOR_PREFIX_PATH}/patient-result`}
                  //component={lazy(() => import(`./results/all/list`))}
                  component={lazy(() => import(`../admin-views/released-results/patient-result/list/`))}
                />
          )}

          {DoesThisUserHasAPermissionToThisComponent(VIEW_SEASONAL_REPORT) && (
                <Route
                  exact
                  path={`${DOCTOR_PREFIX_PATH}/antibiogram-report`}
                  component={lazy(() => import(`./seasonal-report/index.js`))}
                />
            )}
          {DoesThisUserHasAPermissionToThisComponent(VIEW_NEWORDERS) && (
              <Route
                exact
                path={`${DOCTOR_PREFIX_PATH}/order/new`}
                component={lazy(() => import(`../admin-views/neworder/neworder/index`))}
              />
            )}
          
          {DoesThisUserHasAPermissionToThisComponent(VIEW_ALL_INCOMPLETE_RESULTS) && (
            <Route
              exact
              path={`${DOCTOR_PREFIX_PATH}/results/incomplete/all`}
              component={lazy(() => import(`../admin-views/released-results/incomplete-results/list`))}
            />
          )}

          {DoesThisUserHasAPermissionToThisComponent(VIEW_COMPLETED_RESULTS) && (
            <Route
              exact
              path={`${DOCTOR_PREFIX_PATH}/results/completed/all`}
              component={lazy(() => import(`../admin-views/released-results/completed-results/list`))}
            />
          )}
          {DoesThisUserHasAPermissionToThisComponent(VIEW_ALL_ORDERS) && (
            <Route
              exact
              path={`${DOCTOR_PREFIX_PATH}/all-orders`}
              component={lazy(() => import(`../admin-views/orders/all-orders/list/index`))}
            />
          )}

          {DoesThisUserHasAPermissionToThisComponent(VIEW_STAT_ORDERS) && (
            <Route
              exact
              path={`${DOCTOR_PREFIX_PATH}/stat-orders`}
              component={lazy(() => import(`../admin-views/orders/stat-orders/list/index`))}
            />
          )}
          {DoesThisUserHasAPermissionToThisComponent(VIEW_TOP_TESTS) && (
            <Route
              exact
              path={`${DOCTOR_PREFIX_PATH}/top-orders`}
              component={lazy(() => import(`../admin-views/toptests/list/index`))}
            />
          )}

          {DoesThisUserHasAPermissionToThisComponent(VIEW_PANIC_AND_ABNORMAL_TESTS) && (
            <Route
              exact
              path={`${DOCTOR_PREFIX_PATH}/panic-results`}
              component={lazy(() => import(`../admin-views/released-results/panicandabnormaltests/list`))} 
            />
          )}
          {DoesThisUserHasAPermissionToThisComponent(VIEW_DR_PROFILE_UPDATE_PAGE) && (
            <Route
              exact
              path={`${DOCTOR_PREFIX_PATH}/profile/update`}
              component={lazy(() => import(`./doctor-profile-update/EditProfile`))}
            />
          )}

          {/* {DoesThisUserHasAPermissionToThisComponent(VIEW_DISCONTINUED_TEST_DIRECTORIES) && (
            <Route
              exact
              path={`${DOCTOR_PREFIX_PATH}/discontinued-test-directories`}
              component={lazy(() => import(`../admin-views/discontinued-test-directories/list/index`))}
            />
          )} */}

          {DoesThisUserHasAPermissionToThisComponent(VIEW_DRS_CHAT_ROOM) && (
            <Route
              exact
              path={`${DOCTOR_PREFIX_PATH}/chat-room`}
              component={lazy(() => import(`./drs-chat-room/ChatRoom`))}
            />
          )}

          {DoesThisUserHasAPermissionToThisComponent(VIEW_DR_CUSTOMER_SUPPORT_PAGE) && (
            <Route
              exact
              path={`${DOCTOR_PREFIX_PATH}/customer-support`}
              component={lazy(() => import(`./components/CustomerSupportForm`))}
            />
          )}

          {DoesThisUserHasAPermissionToThisComponent(VIEW_TESTDIRECTORIES) && (
            <Route
              exact
              path={`${DOCTOR_PREFIX_PATH}/test-directories`}
              component={lazy(() => import(`../admin-views/test-directory/list/index`))}
            />
          )}

          {DoesThisUserHasAPermissionToThisComponent(VIEW_SHARED_RESULTS_BY_OTHER_DOCTORS) && (
            <Route
              exact
              path={`${DOCTOR_PREFIX_PATH}/results/shared/me`}
              component={lazy(() => import(`./components/SharedResultsByOtherDoctors`))}
            />
          )}

            <Route
            path="*">
                <PageNotFoundPage/>
            </Route>

          </Switch>
          : <UnAuthorizedPage/>

      }
      </Suspense>
    </div>
  );
};

export default DoctorAppViews;
