import { all } from "redux-saga/effects";
import Auth from "./Auth";
import Role from "./Role";
import AgencyTests from "./AgencyTests";
import User from "./User";
import Coupon from "./Coupon";
import Section from "./Section";
import Machine from "./Machine";
import Patient from "./Patient";
import Equipment from "./Equipment";
import CheckList from "./CheckList";
import Maintenance from "./Maintenance";
import Department from "./Department";
import Appointment from "./Appointment";
import BranchLookUp from "./BranchLookUp";
import TestDirectory from "./TestDirectory";
import DiscontinuedTestDirectoryService from "./DiscontinuedTestDirectories";
import TestRegistration from "./TestRegistration";
import ClientCompany from "./ClientCompany";
import BranchTestCapacity from "./BranchTestCapacity";
import ClientCompanyTestDirectory from "./ClientCompanyTestDirectory";
import NewOrder from "./NewOrder";
import OrderMadeByGuestUsers from "./OrderMadeByGuestUser";
import InPersonDelivery from "./InPersonDelivery";
import Financials from "./Financials";
import FinancialReport from "./FinancialReport";
import Notyetreleased from "./Notyetreleased";
import SampleAccepted from "./SampleAccepted";
import SampleInProcess from "./SampleInProcess";
import Released from "./Released";
import PatientResult from "./PatientResult";
import IncompleteResults from "./IncompleteResultsObx";
import Discrepancy from "./Discrepancy";
import DiscrepancyApproved from "./DiscrepanciesStatus/DiscrepancyApproved";
import DiscrepancyDeclined from "./DiscrepanciesStatus/DiscrepancyDeclined";
import DiscrepancyPending from "./DiscrepanciesStatus/DiscrepancyPending";
import AllOrders from "./OrderStatus/AllOrders";
import ApprovedOrders from "./OrderStatus/ApprovedOrders";
import DeclinedOrders from "./OrderStatus/DeclinedOrders";
import PendingOrders from "./OrderStatus/PendingOrders";
import StatOrders from "./StatOrders";
import OrderedOrders from "./OrderStatus/OrderedOrders"
import RedrawSamples from "./OrderStatus/RedrawSamples"
import TopAgencies from "./TopAgencies";
import DashboardReport from "./AdminDashboardReports/DashboardReport";
import TopTests from "./TopTests";
import PendingResults from "./PendingResultsObx";
import TopPanels from "./TopPanels";
import TopBranchesForWalkinCustomer from "./TopBranchesForWalkinCustomers";
import PanicandAbnormalTestResults from "./PanicandAbnormalTestResults";
import PatientTestResultHistory from "./PatientTestResultHistory";
import PatientSearch from "./PatientSearch";
import UploadFile from "./UploadFile";
import UploadCsvFile from "./UploadCsvFile";
import MicroBiology from "./MicroBiology";
import IclPay from "./IclPay";
import DailyReleasedTests from "./DailyReleasedTests";
import DailyDepartmentReleasedTests from "./DailyDepartmentReleasedTests";
import SingleReleasedDailyReleasedTests from "./SingleReleasedDailyReleasedTests";
import CompletedResults from "./CompletedResults";
import StatResults from "./StatResults";
import FaultResults from "./FaultResults";
import ReferableOrder from "./ReferableOrders";
import TestObxPerTechnician from "./TestObxPerTechnician";
import TatApproaching from "./TatApproaching";
import PickupRequest from "./PickupRequest";
import DailyFinancialSummary from "./DailyFinancialSummary";
import SpecimenTracking from "./SpecimenTracking";
import TbMicro from "./TbMicro"
import CoreLabResults from "./CoreLab"
import Pathology from "./Pathology"
import Cost from "./Cost";
import WebLedgerInfo from "./WebLedgerInfo";
import ForgotPassword from "./ForgotPassoword";
import Notifications from "./Notifications"
import UserPreference from "./UserPreference"
import Logistics from "./Logistics";
import DoctorsList from "./DoctorsList";

export default function* rootSaga(getState) {
  yield all([
    Auth(),
    User(),
    Role(),
    AgencyTests(),
    Coupon(),
    Section(),
    Machine(),
    Patient(),
    Equipment(),
    CheckList(),
    Maintenance(),
    Department(),
    Appointment(),
    BranchLookUp(),
    TestDirectory(),
    DiscontinuedTestDirectoryService(),
    TestRegistration(),
    ClientCompany(),
    BranchTestCapacity(),
    ClientCompanyTestDirectory(),
    NewOrder(),
    OrderMadeByGuestUsers(),
    InPersonDelivery(),
    Financials(),
    FinancialReport(),
    Notyetreleased(),
    SampleAccepted(),
    SampleInProcess(),
    Released(),
    PatientResult(),
    CompletedResults(),
    IncompleteResults(),
    Discrepancy(),
    DiscrepancyApproved(),
    DiscrepancyPending(),
    DiscrepancyDeclined(),
    StatResults(),
    AllOrders(),
    OrderedOrders(),
    RedrawSamples(),
    DeclinedOrders(),
    PendingOrders(),
    StatOrders(),
    ApprovedOrders(),
    TopAgencies(),
    TopTests(),
    PendingResults(),
    DashboardReport(),
    TopPanels(),
    TopBranchesForWalkinCustomer(),
    PanicandAbnormalTestResults(),
    PatientTestResultHistory(),
    PatientSearch(),
    UploadFile(),
    UploadCsvFile(),
    MicroBiology(),
    IclPay(),
    DailyReleasedTests(),
    SingleReleasedDailyReleasedTests(),
    DailyDepartmentReleasedTests(),
    FaultResults(),
    ReferableOrder(),
    TestObxPerTechnician(),
    TatApproaching(),
    PickupRequest(),
    DailyFinancialSummary(),
    SpecimenTracking(),
    PickupRequest(),
    TbMicro(),
    CoreLabResults(),
    Pathology(),
    Cost(),
    WebLedgerInfo(),
    ForgotPassword(),
    Notifications(),
    UserPreference(),
    Logistics(),
    DoctorsList()
  ]);
}
