import { FETCH_ORDERED_ORDERS_FAILURE, FETCH_ORDERED_ORDERS_REQUEST, FETCH_ORDERED_ORDERS_SUCCESS } from "constants/OrderStatus/OrderedOrders"

export const fetchOrderedOrdersRequest = () => {
    return {
        type: FETCH_ORDERED_ORDERS_REQUEST,
    }
}

export const fetchOrderedOrdersSuccess = (payload) => {
    return {
        type: FETCH_ORDERED_ORDERS_SUCCESS,
        payload: payload
    }
}

export const fetchOrderedOrdersFailure = (payload) => {
    return {
        type: FETCH_ORDERED_ORDERS_FAILURE,
        payload: payload
    }
}