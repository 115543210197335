import fetch from "auth/FetchInterceptor";

const OrderedOrders = {};

OrderedOrders.getOrderedOrders = function () {
    return fetch({
        url: `/reports/ordered-orders`,
        method: "get",
    });
};

export default OrderedOrders;
