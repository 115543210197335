import fetch from "auth/FetchInterceptor";

const DashboardReport = {};

DashboardReport.getDashboard = function (payload) {
  return fetch({
    url: `/getdashboard?startdate=
              ${payload.startdate}&enddate=
              ${payload.enddate}&agency=
              ${payload.agency}`,
    method: "get",
  });
};

DashboardReport.getCashierDashboard = function () {
  return fetch({
    url: "/cashier-dashboard",
    method: "get",
  });
};


export default DashboardReport;
