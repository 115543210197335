import {
  all,
  takeEvery,
  put,
  fork,
  call,
  takeLatest,
} from "redux-saga/effects";
import moment from "moment";
import {
  AUTH_TOKEN,
  SIGNIN,
  SIGNOUT,
  SIGNUP,
  SIGNIN_WITH_GOOGLE,
  SIGNIN_WITH_FACEBOOK,
  SIGN_IN_WITH_EMAIL_AND_PASSWORD_REQUEST,
  SIGN_UP_WITH_EMAIL_AND_PASSWORD_REQUEST,
  CURRENT_USER,
  LOGIN_EXPIRATION_TIME, LOGIS_AUTH_TOKEN, LOGIS_TOKEN_EXPIRATION_TIME, LOGIS_LOCATION_LIST, USER_PREFERENCE,
  PREVIOUSLY_LOGGED_IN,
} from "../constants/Auth";
import {
  showAuthMessage,
  authenticated,
  signOutSuccess,
  signUpSuccess,
  signInWithGoogleAuthenticated,
  signInWithFacebookAuthenticated,
  signUpWithEmailAndPasswordFailure,
  signInWithEmailAndPasswordFailure,
  signInWithEmailAndPasswordSuccess,
  signUpWithEmailAndPasswordSuccess,
} from "../actions/Auth";

import FirebaseService from "services/FirebaseService";
import JwtAuthService from "services/JwtAuthService";
import { TOKEN_EXPIRATION_TIME } from "configs/AppConfig";
import { message } from "antd";
import localStorage from "redux-persist/es/storage";

export function* signInWithFBEmail() {
  yield takeEvery(SIGNIN, function* ({ payload }) {
    const { email, password } = payload;
    try {
      const user = yield call(
        FirebaseService.signInEmailRequest,
        email,
        password
      );
      if (user.message) {
        yield put(showAuthMessage(user.message));
      } else {
        //localStorage.setItem(AUTH_TOKEN, user.user.uid);
        yield put(authenticated(user.user.uid));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
    try {
      const signOutUser = yield call(FirebaseService.signOutRequest);
      if (signOutUser === undefined) {
        localStorage.removeItem(AUTH_TOKEN);
        localStorage.removeItem(CURRENT_USER);
        localStorage.removeItem(LOGIN_EXPIRATION_TIME);
        localStorage.removeItem(LOGIS_AUTH_TOKEN);
        localStorage.removeItem(LOGIS_TOKEN_EXPIRATION_TIME);
        localStorage.removeItem(LOGIS_LOCATION_LIST);
        yield put(signOutSuccess(signOutUser));
      } else {
        yield put(showAuthMessage(signOutUser.message));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* signUpWithFBEmail() {
  yield takeEvery(SIGNUP, function* ({ payload }) {
    const { email, password } = payload;
    try {
      const user = yield call(
        FirebaseService.signUpEmailRequest,
        email,
        password
      );
      if (user.message) {
        yield put(showAuthMessage(user.message));
      } else {
        localStorage.setItem(AUTH_TOKEN, user.user.uid);
        yield put(signUpSuccess(user.user.uid));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  });
}

export function* signInWithFBGoogle() {
  yield takeEvery(SIGNIN_WITH_GOOGLE, function* () {
    try {
      const user = yield call(FirebaseService.signInGoogleRequest);
      if (user.message) {
        yield put(showAuthMessage(user.message));
      } else {
        localStorage.setItem(AUTH_TOKEN, user.user.uid);
        yield put(signInWithGoogleAuthenticated(user.user.uid));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  });
}

export function* signInWithFacebook() {
  yield takeEvery(SIGNIN_WITH_FACEBOOK, function* () {
    try {
      const user = yield call(FirebaseService.signInFacebookRequest);
      if (user.message) {
        yield put(showAuthMessage(user.message));
      } else {
        localStorage.setItem(AUTH_TOKEN, user.user.uid);
        yield put(signInWithFacebookAuthenticated(user.user.uid));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  });
}

export function* onSignInWithEmailAndPassword() {
  yield takeLatest( SIGN_IN_WITH_EMAIL_AND_PASSWORD_REQUEST, function* ({ payload }) {
      try {
        const result = yield call(JwtAuthService.login, payload);
        if (!result.status) {
          yield put(signInWithEmailAndPasswordFailure());
          yield put(showAuthMessage(result.message));
        } else {
          const token_expiration_time = {
              "AUTH_TOKEN" : result.data.accessToken,
              "token_expiration_time" : moment().add(TOKEN_EXPIRATION_TIME,'hours')
          };
          localStorage.setItem(LOGIN_EXPIRATION_TIME, JSON.stringify(token_expiration_time));
          localStorage.setItem(AUTH_TOKEN, result.data.accessToken);
          localStorage.setItem(USER_PREFERENCE, result.data.currentUser.general_user_preference);
          localStorage.setItem(CURRENT_USER, JSON.stringify(result.data.currentUser));
          localStorage.setItem(PREVIOUSLY_LOGGED_IN,  result.data.logged_in_before);
          yield put(signInWithEmailAndPasswordSuccess(result.message));
          //yield put(authenticated(result.data));
          window.location.reload(true);
        }
      } catch (error) {
        message.error("Connection is not established");
        yield put(signInWithEmailAndPasswordFailure());
        // console.log(error.response.data);
      }
    }
  );
}

export function* onSignUpWithEmailAndPassword() {
  yield takeLatest(
    SIGN_UP_WITH_EMAIL_AND_PASSWORD_REQUEST,
    function* ({ payload }) {
      try {
        // yield put(signUpWithEmailAndPasswordFailure(null))
        const result = yield call(JwtAuthService.register, payload);
        if (!result.status) { 
          // yield put(showAuthMessage(result.message));
          // yield put(showAuthMessage(result.data.errors));
          const errors = result.data.errors;
          for (const key in errors) {
            if (errors.hasOwnProperty(key)) {
              const messages = errors[key];
              for (const message of messages) {
                yield put(showAuthMessage(message));
              }
            }
          }
          if (result.code === 422) {
              yield put(signUpWithEmailAndPasswordFailure(result.data.errors))
          }
        } else {
          message.success("Account created successfully");
          // localStorage.setItem(AUTH_TOKEN, result.data.accessToken);
          // localStorage.setItem(CURRENT_USER, JSON.stringify(result.data.currentUser));
          yield put(signUpWithEmailAndPasswordSuccess(result.data));
        }
      } catch (error) {
        yield console.log(error);
      }
    }
  );
}

export default function* rootSaga() {
  yield all([
    fork(signInWithFBEmail),
    fork(signOut),
    fork(signUpWithFBEmail),
    fork(signInWithFBGoogle),
    fork(signInWithFacebook),
    fork(onSignInWithEmailAndPassword),
    fork(onSignUpWithEmailAndPassword),
  ]);
}
