import Loading from "components/shared-components/Loading";
import { ADMIN_PREFIX_PATH, ROLE_GUEST, ROLE_QUALITY_CONTROL } from "configs/AppConfig";
import {
  DAILY_DEPARTMENT_RELEASED_TESTS,
  DAILY_RELEASED_TESTS,
  DAILY_SINGLE_RELEASED_TESTS,
  Sample_Managment,
  UPLOAD_CSV_PANELS,
  UPLOAD_FINANCIAL_REPORT,
  VIEW_AGENCY_TEST_ASSOCIATION_FORM,
  VIEW_ALL_DISCREPANCIES,
  VIEW_ALL_INCOMPLETE_RESULTS,
  VIEW_ALL_ORDERS,
  VIEW_ALL_RELEASED_RESULTS,
  VIEW_APPROVED_DESCREPANCIES,
  VIEW_APPROVED_ORDERS,
  VIEW_BRANCHLOOKUPS,
  VIEW_BRANCH_TEST_CAPACITIES,
  VIEW_CASHIER_DASHBOARD,
  VIEW_CLIENTCOMPANIES,
  VIEW_CLIENTCOMPANYTESTDIRECTORIES,
  VIEW_COMPLETED_RESULTS,
  VIEW_COUPONS,
  VIEW_DAILY_FINANCIAL_SUMMARY,
  VIEW_DASHBOARD,
  VIEW_DECLINED_DESCREPANCIES,
  VIEW_DELCINED_ORDERS,
  VIEW_DEPARTMENTS,
  VIEW_DISCONTINUED_TEST_DIRECTORIES,
  VIEW_FAULT_RESULTS,
  VIEW_FINANCIAL,
  VIEW_FINANCIAL_REPORTS,
  VIEW_INPERSON_RESULTS,
  VIEW_MICRO_BIOLOGY_RESULTS,
  VIEW_NEWORDERS,
  VIEW_NOT_RELEASED_ORDERS,
  VIEW_ORDERED_ORDERS,
  VIEW_PANEL_OBR,
  VIEW_PANIC_AND_ABNORMAL_TESTS,
  VIEW_PANIC_STAT_RESULTS,
  VIEW_PATIENT_RESULT_HISTORY,
  VIEW_PENDING_CORELAB_RESULTS,
  VIEW_PENDING_DISCREPANCIES,
  VIEW_PENDING_ORDERS,
  VIEW_PENDING_RESULTS,
  VIEW_PENDING_TBMICRO_RESULTS,
  VIEW_REDRAW_ORDERS,
  VIEW_REFERABLE_ORDERS,
  VIEW_RELEASED_PANEL_FOR_ICL,
  VIEW_REPORTS,
  VIEW_RESULTS_FOR_GUEST_USERS,
  VIEW_ROLES,
  VIEW_SAMPLE_IN_PROCESS,
  VIEW_SAMPLE_NOT_RECIEVED_ORDERS,
  VIEW_STAT_ORDERS,
  VIEW_TAT_APPROACHING,
  VIEW_TESTDIRECTORIES,
  VIEW_TEST_OBX_PER_TECHNICIAN,
  VIEW_TOP_AGENCIES,
  VIEW_TOP_BRANCH_FOR_WALKIN_CUSTOMERS,
  VIEW_TOP_TESTS,
  VIEW_TV_SCREEN_RESULTS,
  VIEW_USERS,
} from "configs/UserPermissions";
import { Suspense, lazy } from "react";
import { Route, Switch } from "react-router";
import { CURRENT_USER, PREVIOUSLY_LOGGED_IN } from "redux/constants/Auth";
import { DoesThisUserHasAPermissionToThisComponent, doesThisUserIsCashier } from "utils/CurrentUserUtil";
import PageNotFoundPage from "views/PageNotFound";
import UnAuthorizedPage from "views/UnAuthorized";
import { UPLOAD_MICRO_BIOLOGIES_RESULTS } from "../../configs/UserPermissions";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";

const AdminViews = () => {
  const currentUser = JSON.parse(localStorage.getItem(CURRENT_USER));
  const role = currentUser?.role;
  const location = useLocation();
  const history = useHistory();
  let previouslyLoggedIn = localStorage.getItem(PREVIOUSLY_LOGGED_IN);
  if (previouslyLoggedIn?.toString() !== "1" && location.pathname !== "/admin/reset/") {
    //alert("called")
    history.push("/admin/reset/");
    window.location.reload(true); // this is neccessary 
  }

  const currentLocation = location.pathname;
  return (

    <div>
      <Suspense fallback={<Loading cover="content" />}>

        {role !== ROLE_QUALITY_CONTROL && role !== ROLE_GUEST ? (
          <Switch>

            {DoesThisUserHasAPermissionToThisComponent(VIEW_DASHBOARD) && (
              <Route
                exact
                path={`${ADMIN_PREFIX_PATH}`}
                component={lazy(() => import(`./home`))}
              />
            )}

            {(DoesThisUserHasAPermissionToThisComponent(VIEW_CASHIER_DASHBOARD) && doesThisUserIsCashier()) && (
              < Route
                exact
                path={`${ADMIN_PREFIX_PATH + '/cashier'}`}
                component={lazy(() => import(`./home`))}
              />
            )}

            <Route
              exact
              path={`${ADMIN_PREFIX_PATH}/reset`}
              component={lazy(() => import(`./reset-password`))}
            />

            {DoesThisUserHasAPermissionToThisComponent(VIEW_ROLES) && (
              <Route
                exact
                path={`${ADMIN_PREFIX_PATH}/roles`}
                component={lazy(() => import(`./role/list`))}
              />
            )}
            {DoesThisUserHasAPermissionToThisComponent(VIEW_USERS) && (
              <Route
                exact
                path={`${ADMIN_PREFIX_PATH}/users`}
                component={lazy(() => import(`./user/list`))}
              />
            )}

            {DoesThisUserHasAPermissionToThisComponent(VIEW_USERS) && (
              <Route
                exact
                path={`${ADMIN_PREFIX_PATH}/tonight-practice`}
                component={lazy(() => import(`./tonight-practice/list`))}
              />
            )}


            {DoesThisUserHasAPermissionToThisComponent(
              VIEW_AGENCY_TEST_ASSOCIATION_FORM
            ) && (
                <Route
                  exact
                  path={`${ADMIN_PREFIX_PATH}/agencies-tests`}
                  component={lazy(() => import(`./agencies-test/list`))}
                />
              )}
            {DoesThisUserHasAPermissionToThisComponent(VIEW_COUPONS) && (
              <Route
                exact
                path={`${ADMIN_PREFIX_PATH}/coupons`}
                component={lazy(() => import(`./coupon/list`))}
              />
            )}
            {DoesThisUserHasAPermissionToThisComponent(VIEW_DEPARTMENTS) && (
              <Route
                exact
                path={`${ADMIN_PREFIX_PATH}/departments`}
                component={lazy(() => import(`./department/list`))}
              />
            )}
            {DoesThisUserHasAPermissionToThisComponent(VIEW_NEWORDERS) && (
              <Route
                exact
                path={`${ADMIN_PREFIX_PATH}/neworder`}
                component={lazy(() => import(`./neworder/neworder`))}
              />
            )}
            {DoesThisUserHasAPermissionToThisComponent(VIEW_NEWORDERS) && (
              <Route
                exact
                path={`${ADMIN_PREFIX_PATH}/order-pickup-request`}
                component={lazy(() => import(`./order-pickup-request/list`))}
              />
            )}
            {DoesThisUserHasAPermissionToThisComponent(VIEW_NEWORDERS) && (
              <Route
                exact
                path={`${ADMIN_PREFIX_PATH}/show-patient-timeline`}
                component={lazy(() => import(`./time-line`))}
              />
            )}

            {DoesThisUserHasAPermissionToThisComponent(VIEW_DASHBOARD) && (
              <Route
                exact
                path={`${ADMIN_PREFIX_PATH}/logistics-request`}
                component={lazy(() => import(`./logistics-request/list`))}
              />
            )}

            {DoesThisUserHasAPermissionToThisComponent(
              DAILY_RELEASED_TESTS
            ) && (
                <Route
                  exact
                  path={`${ADMIN_PREFIX_PATH}/daily-released-tests`}
                  component={lazy(() => import(`./daily-released-tests/list`))}
                />
              )}

            {DoesThisUserHasAPermissionToThisComponent(
              VIEW_TEST_OBX_PER_TECHNICIAN
            ) && (
                <Route
                  exact
                  path={`${ADMIN_PREFIX_PATH}/testobx-per-technician`}
                  component={lazy(() => import(`./testobx-per-technician/list`))}
                />
              )}

            {DoesThisUserHasAPermissionToThisComponent(
              DAILY_SINGLE_RELEASED_TESTS
            ) && (
                <Route
                  exact
                  path={`${ADMIN_PREFIX_PATH}/single-daily-released-tests`}
                  component={lazy(() =>
                    import(`./single-daily-released-tests/list`)
                  )}
                />
              )}

            {DoesThisUserHasAPermissionToThisComponent(
              DAILY_DEPARTMENT_RELEASED_TESTS
            ) && (
                <Route
                  exact
                  path={`${ADMIN_PREFIX_PATH}/daily-department-released-tests`}
                  component={lazy(() =>
                    import(`./daily-department-released-tests/list`)
                  )}
                />
              )}

            {DoesThisUserHasAPermissionToThisComponent(
              UPLOAD_MICRO_BIOLOGIES_RESULTS
            ) && (
                <Route
                  exact
                  path={`${ADMIN_PREFIX_PATH}/uploadresult`}
                  component={lazy(() => import(`./upload-file/view`))}
                />
              )}

            {DoesThisUserHasAPermissionToThisComponent(
              UPLOAD_CSV_PANELS
            ) && (
                <Route
                  exact
                  path={`${ADMIN_PREFIX_PATH}/uploadcsv`}
                  component={lazy(() => import(`./upload-csv-panels/view`))}
                />
              )}

            {DoesThisUserHasAPermissionToThisComponent(
              VIEW_RESULTS_FOR_GUEST_USERS
            ) && (
                <Route
                  exact
                  path={`${ADMIN_PREFIX_PATH}/ordermadebyguestusers`}
                  component={lazy(() =>
                    import(`./released-results/ordermadebyguestusers/list`)
                  )}
                />
              )}
            {DoesThisUserHasAPermissionToThisComponent(VIEW_DASHBOARD) && (
              <Route
                exact
                path={`${ADMIN_PREFIX_PATH}/dashboard`}
                component={lazy(() => import(`./dashboard/`))}
              />
            )}
            {DoesThisUserHasAPermissionToThisComponent(VIEW_FINANCIAL) && (
              <Route
                exact
                path={`${ADMIN_PREFIX_PATH}/financial`}
                component={lazy(() => import(`./financial/list`))}
              />
            )}
            {DoesThisUserHasAPermissionToThisComponent(VIEW_FINANCIAL_REPORTS) && (
              <Route
                exact
                path={`${ADMIN_PREFIX_PATH}/cost`}
                component={lazy(() => import(`./cost/list`))}
              />
            )}
            {DoesThisUserHasAPermissionToThisComponent(
              UPLOAD_FINANCIAL_REPORT
            ) && (
                <Route

                  exact
                  path={`${ADMIN_PREFIX_PATH}/uploadfinancial`}
                  component={lazy(() =>
                    import(`./financial-report`)
                  )}
                />
              )}
            {DoesThisUserHasAPermissionToThisComponent(VIEW_TAT_APPROACHING) && (
              <Route
                exact
                path={`${ADMIN_PREFIX_PATH}/tat-approaching`}
                component={lazy(() => import(`./tat-approaching/list`))}
              />
            )}

            {DoesThisUserHasAPermissionToThisComponent(Sample_Managment) && (
              <Route
                exact
                path={`${ADMIN_PREFIX_PATH}/specimen_tracking`}
                component={lazy(() => import(`./specimen_tracking/list`))}
              />
            )}
            {DoesThisUserHasAPermissionToThisComponent(VIEW_DAILY_FINANCIAL_SUMMARY) && (
              <Route
                exact
                path={`${ADMIN_PREFIX_PATH}/daily_financial_summary`}
                component={lazy(() => import(`./daily_financial_summary/list`))}
              />
            )}


            {DoesThisUserHasAPermissionToThisComponent(VIEW_TAT_APPROACHING) && (
              <Route
                exact
                path={`${ADMIN_PREFIX_PATH}/daily_financial_summary`}
                component={lazy(() => import(`./daily_financial_summary/list`))}
              />
            )}
            {DoesThisUserHasAPermissionToThisComponent(VIEW_TAT_APPROACHING) && (
              <Route
                exact
                path={`${ADMIN_PREFIX_PATH}/specimen_tracking`}
                component={lazy(() => import(`./specimen_tracking/list`))}
              />
            )}
            {DoesThisUserHasAPermissionToThisComponent(
              VIEW_APPROVED_ORDERS
            ) && (
                <Route
                  exact
                  path={`${ADMIN_PREFIX_PATH}/approved-orders`}
                  component={lazy(() => import(`./orders/approved-orders/list`))}
                />
              )}

            {DoesThisUserHasAPermissionToThisComponent(VIEW_PENDING_ORDERS) && (
              <Route
                exact
                path={`${ADMIN_PREFIX_PATH}/pending-orders`}
                component={lazy(() => import(`./orders/pending-orders/list`))}
              />
            )}

            {DoesThisUserHasAPermissionToThisComponent(
              VIEW_DELCINED_ORDERS
            ) && (
                <Route
                  exact
                  path={`${ADMIN_PREFIX_PATH}/declined-orders`}
                  component={lazy(() => import(`./orders/declined-orders/list`))}
                />
              )}

            {DoesThisUserHasAPermissionToThisComponent(VIEW_ALL_ORDERS) && (
              <Route
                exact
                path={`${ADMIN_PREFIX_PATH}/all-orders`}
                component={lazy(() => import(`./orders/all-orders/list`))}
              />
            )}

            {DoesThisUserHasAPermissionToThisComponent(
              VIEW_REFERABLE_ORDERS
            ) && (
                <Route
                  exact
                  path={`${ADMIN_PREFIX_PATH}/referable-orders`}
                  component={lazy(() => import(`./referable-orders/list`))}
                />
              )}

            {DoesThisUserHasAPermissionToThisComponent(
              VIEW_RELEASED_PANEL_FOR_ICL
            ) && (
                <Route
                  exact
                  path={`${ADMIN_PREFIX_PATH}/released-panels`}
                  component={lazy(() => import(`./orders/released-orders/list`))}
                />
              )}

            {DoesThisUserHasAPermissionToThisComponent(
              VIEW_ALL_RELEASED_RESULTS
            ) && (
                <Route
                  exact
                  path={`${ADMIN_PREFIX_PATH}/patient-result`}
                  component={lazy(() =>
                    import(`./released-results/patient-result/list`)
                  )}
                />
              )}
            {DoesThisUserHasAPermissionToThisComponent(
              VIEW_COMPLETED_RESULTS
            ) && (
                <Route
                  exact
                  path={`${ADMIN_PREFIX_PATH}/completed-results`}
                  component={lazy(() =>
                    import(`./released-results/completed-results/list`)
                  )}
                />
              )}

            {DoesThisUserHasAPermissionToThisComponent(
              VIEW_ALL_INCOMPLETE_RESULTS
            ) && (
                <Route
                  exact
                  path={`${ADMIN_PREFIX_PATH}/incomplete-results`}
                  component={lazy(() =>
                    import(`./released-results/incomplete-results/list`)
                  )}
                />
              )}

            {DoesThisUserHasAPermissionToThisComponent(
              VIEW_MICRO_BIOLOGY_RESULTS
            ) && (
                <Route
                  exact
                  path={`${ADMIN_PREFIX_PATH}/micro-biology`}
                  component={lazy(() =>
                    import(`./released-results/Micro-Biology/list`)
                  )}
                />
              )}

            {DoesThisUserHasAPermissionToThisComponent(
              VIEW_PANIC_AND_ABNORMAL_TESTS
            ) && (
                <Route
                  exact
                  path={`${ADMIN_PREFIX_PATH}/panicandabnormaltests`}
                  component={lazy(() =>
                    import(`./released-results/panicandabnormaltests/list`)
                  )}
                />
              )}

            {DoesThisUserHasAPermissionToThisComponent(
              VIEW_INPERSON_RESULTS
            ) && (
                <Route
                  exact
                  path={`${ADMIN_PREFIX_PATH}/in-person-result-delivery`}
                  component={lazy(() =>
                    import(`./released-results/in-person-result-delivery/list`)
                  )}
                />
              )}

            {DoesThisUserHasAPermissionToThisComponent(
              VIEW_PATIENT_RESULT_HISTORY
            ) && (
                <Route
                  exact
                  path={`${ADMIN_PREFIX_PATH}/patienttestresulthistory`}
                  component={lazy(() =>
                    import(`./released-results/patienttestresulthistory/list/index`)
                  )}
                />
              )}

            {DoesThisUserHasAPermissionToThisComponent(
              VIEW_ALL_DISCREPANCIES
            ) && (
                <Route
                  exact
                  path={`${ADMIN_PREFIX_PATH}/discrepancy/all`}
                  component={lazy(() => import(`./discrepancy/all/list`))}
                />
              )}

            {DoesThisUserHasAPermissionToThisComponent(
              VIEW_PANIC_STAT_RESULTS
            ) && (
                <Route
                  exact
                  path={`${ADMIN_PREFIX_PATH}/stat-results`}
                  component={lazy(() => import(`./released-results/stat-results`))}
                />
              )}

            {DoesThisUserHasAPermissionToThisComponent(VIEW_FAULT_RESULTS) && (
              <Route
                exact
                path={`${ADMIN_PREFIX_PATH}/fault-results`}
                component={lazy(() =>
                  import(`./released-results/fault-results/list`)
                )}
              />
            )}

            {DoesThisUserHasAPermissionToThisComponent(
              VIEW_APPROVED_DESCREPANCIES
            ) && (
                <Route
                  exact
                  path={`${ADMIN_PREFIX_PATH}/discrepancy/discrepancy-approved`}
                  component={lazy(() =>
                    import(`./discrepancy/discrepancy-approved`)
                  )}
                />
              )}

            {DoesThisUserHasAPermissionToThisComponent(
              VIEW_PENDING_DISCREPANCIES
            ) && (
                <Route
                  exact
                  path={`${ADMIN_PREFIX_PATH}/discrepancy/discrepancy-pending`}
                  component={lazy(() =>
                    import(`./discrepancy/discrepancy-pending`)
                  )}
                />
              )}

            {DoesThisUserHasAPermissionToThisComponent(
              VIEW_DECLINED_DESCREPANCIES
            ) && (
                <Route
                  exact
                  path={`${ADMIN_PREFIX_PATH}/discrepancy/discrepancy-declined`}
                  component={lazy(() =>
                    import(`./discrepancy/discrepancy-declined`)
                  )}
                />
              )}

            {DoesThisUserHasAPermissionToThisComponent(VIEW_TOP_AGENCIES) && (
              <Route
                exact
                path={`${ADMIN_PREFIX_PATH}/top-agencies`}
                component={lazy(() => import(`./topagencies/list`))}
              />
            )}

            {DoesThisUserHasAPermissionToThisComponent(VIEW_TOP_TESTS) && (
              <Route
                exact
                path={`${ADMIN_PREFIX_PATH}/top-tests`}
                component={lazy(() => import(`./toptests/list`))}
              />
            )}

            {DoesThisUserHasAPermissionToThisComponent(
              VIEW_PENDING_RESULTS
            ) && (
                <Route
                  exact
                  path={`${ADMIN_PREFIX_PATH}/pending-results-obx`}
                  component={lazy(() => import(`./pending-results-obx/list`))}
                />
              )}

            {DoesThisUserHasAPermissionToThisComponent(VIEW_PANEL_OBR) && (
              <Route
                exact
                path={`${ADMIN_PREFIX_PATH}/top-panels`}
                component={lazy(() => import(`./top-panels/list`))}
              />
            )}

            {DoesThisUserHasAPermissionToThisComponent(
              VIEW_TOP_BRANCH_FOR_WALKIN_CUSTOMERS
            ) && (
                <Route
                  exact
                  path={`${ADMIN_PREFIX_PATH}/top-branchs-for-walkin-customers`}
                  component={lazy(() =>
                    import(`./top-branchs-for-walkin-customers/list`)
                  )}
                />
              )}
            {DoesThisUserHasAPermissionToThisComponent(
              VIEW_NOT_RELEASED_ORDERS
            ) && (
                <Route
                  exact
                  path={`${ADMIN_PREFIX_PATH}/not-yet-released`}
                  component={lazy(() => import(`./orders/Notyetreleased/list`))}
                />
              )}
            {DoesThisUserHasAPermissionToThisComponent(
              VIEW_SAMPLE_NOT_RECIEVED_ORDERS
            ) && (
                <Route
                  exact
                  path={`${ADMIN_PREFIX_PATH}/sample-accepted`}
                  component={lazy(() => import(`./orders/SampleAccepted/list`))}
                />
              )}

            {DoesThisUserHasAPermissionToThisComponent(VIEW_STAT_ORDERS) && (
              <Route
                exact
                path={`${ADMIN_PREFIX_PATH}/stat-orders`}
                component={lazy(() => import(`./orders/stat-orders/list`))}
              />
            )}

            {DoesThisUserHasAPermissionToThisComponent(
              VIEW_SAMPLE_IN_PROCESS
            ) && (
                <Route
                  exact
                  path={`${ADMIN_PREFIX_PATH}/sample-in-process`}
                  component={lazy(() => import(`./sample-in-process/list`))}
                />
              )}

            {DoesThisUserHasAPermissionToThisComponent(VIEW_REPORTS) && (
              <Route
                exact
                path={`${ADMIN_PREFIX_PATH}/reports`}
                component={lazy(() => import(`./reports/list`))}
              />
            )}

            {DoesThisUserHasAPermissionToThisComponent(
              VIEW_TESTDIRECTORIES
            ) && (
                <Route
                  exact
                  path={`${ADMIN_PREFIX_PATH}/test-directories`}
                  component={lazy(() => import(`./test-directory/list`))}
                />
              )}

            {DoesThisUserHasAPermissionToThisComponent(
              VIEW_PENDING_CORELAB_RESULTS
            ) && (
                <Route
                  exact
                  path={`${ADMIN_PREFIX_PATH}/core_lab`}
                  component={lazy(() => import(`./core_lab/list`))}
                />
              )}

            {DoesThisUserHasAPermissionToThisComponent(
              VIEW_TV_SCREEN_RESULTS
            ) && (
                <Route
                  exact
                  path={`${ADMIN_PREFIX_PATH}/pathology`}
                  component={lazy(() => import(`./pathology/list`))}
                />
              )}

            {DoesThisUserHasAPermissionToThisComponent(
              VIEW_PENDING_TBMICRO_RESULTS
            ) && (
                <Route
                  exact
                  path={`${ADMIN_PREFIX_PATH}/tb_micro`}
                  component={lazy(() => import(`./tb_micro/list`))}
                />
              )}

            {DoesThisUserHasAPermissionToThisComponent(
              VIEW_DISCONTINUED_TEST_DIRECTORIES
            ) && (
                <Route
                  exact
                  path={`${ADMIN_PREFIX_PATH}/discontinued-test-directories`}
                  component={lazy(() =>
                    import(`./discontinued-test-directories/list`)
                  )}
                />
              )}

            {DoesThisUserHasAPermissionToThisComponent(VIEW_BRANCHLOOKUPS) && (
              <Route
                exact
                path={`${ADMIN_PREFIX_PATH}/branch-look-ups`}
                component={lazy(() => import(`./branch-look-up/list`))}
              />
            )}
            {DoesThisUserHasAPermissionToThisComponent(
              VIEW_BRANCH_TEST_CAPACITIES
            ) && (
                <Route
                  exact
                  path={`${ADMIN_PREFIX_PATH}/branch-test-capacities`}
                  component={lazy(() => import(`./branch-test-capacity/list`))}
                />
              )}

            {DoesThisUserHasAPermissionToThisComponent(
              VIEW_CLIENTCOMPANIES
            ) && (
                <Route
                  exact
                  path={`${ADMIN_PREFIX_PATH}/client-companies`}
                  component={lazy(() => import(`./client-company/list`))}
                />
              )}

            {DoesThisUserHasAPermissionToThisComponent(
              VIEW_CLIENTCOMPANYTESTDIRECTORIES
            ) && (
                <Route

                  exact
                  path={`${ADMIN_PREFIX_PATH}/client-company-test-directories`}
                  component={lazy(() =>
                    import(`./client-company-test-directory/list`)
                  )}
                />
              )}

            {/* New Routes for cashier  */}

            {/* Routes for Order status */}

            {DoesThisUserHasAPermissionToThisComponent(VIEW_ORDERED_ORDERS) && (
              <Route
                exact
                path={`${ADMIN_PREFIX_PATH}/ordered-orders`}
                component={lazy(() => import(`./orders/ordered-orders`))}
              />
            )}

            {DoesThisUserHasAPermissionToThisComponent(VIEW_APPROVED_ORDERS) && (
              <Route
                exact
                path={`${ADMIN_PREFIX_PATH}/delivered`}
                component={lazy(() => import(`./orders/approved-orders/list`))}
              />
            )}

            {DoesThisUserHasAPermissionToThisComponent(VIEW_SAMPLE_IN_PROCESS) && (
              <Route
                exact
                path={`${ADMIN_PREFIX_PATH}/in-lab-process`}
                component={lazy(() => import(`./orders/all-orders/list`))}
              />
            )}

            {DoesThisUserHasAPermissionToThisComponent(VIEW_DELCINED_ORDERS) && (
              <Route
                exact
                path={`${ADMIN_PREFIX_PATH}/rejected`}
                component={lazy(() => import(`./orders/declined-orders/list`))}
              />
            )}

            {DoesThisUserHasAPermissionToThisComponent(VIEW_REDRAW_ORDERS) && (
              <Route
                exact
                path={`${ADMIN_PREFIX_PATH}/redraw-samples`}
                component={lazy(() => import(`./orders/Redraw-samples`))}
              />
            )}

            <Route path="*">
              <PageNotFoundPage exceptionalPage={currentLocation} />
            </Route>
          </Switch>
        ) : (
          <UnAuthorizedPage />
        )}
      </Suspense>
    </div>
  );
};

export default AdminViews;
